<template>
    <div class="topic">
        
        <div class="topic-box" :class="topicIsTrue===true?'succ':topicIsTrue===false?'err':''">
            <div class="topic-con" id="topicMain">
                <span :key="n" v-for="(item,n) in topicData['例题']">
                    <span :key="n+item[0]" v-if="item[0]=='str'" class="item">{{item[1]}}</span>
                    <span :key="n+item[0]" v-else-if="item[0]=='tex'" class="tex_item" :id="'tex_item_'+n"></span>
                </span>
            </div>
            
            <div class="option" v-if="topicData['答题方式'] == '单选'">
                <ul>
                    <template v-for="(item,n) in topicData['选项']">
                        <li v-if="active == '' || active == 'on'" :class="checkNum==n ? active: ''" :key="n" @click="optClick(n)">
                            <span :class="'option_span option_span_'+n"></span>
                        </li>
                        <li v-else-if="active=='err'" :class="topicData['解'][n]?'no':checkNum==n?'err':''" :key="n">
                            <span :class="'option_span option_span_'+n"></span>
                        </li>
                        <li v-else-if="active=='succ'" :class="checkNum==n ? active: ''" :key="n">
                            <span :class="'option_span option_span_'+n"></span>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="option" v-if="topicData['答题方式'] == '多选'">
                <ul>
                    <template v-for="(item,n) in topicData['选项']">
                        <li v-if="!submitStatu && checkMoreArr.length>0 && checkMoreArr[n].isChecked" :class="checkMoreArr[n].isChecked?'on':''" :key="n" @click="optClick(n)">
                            <span :class="'option_span option_span_'+n"></span>
                        </li>
                        <li v-else-if="submitStatu && checkMoreArr.length>0 && checkMoreArr[n].isChecked && checkMoreArr[n].answer" class="succ" :key="n">
                            <span :class="'option_span option_span_'+n"></span>
                        </li>
                        <li v-else-if="submitStatu && checkMoreArr.length>0 && checkMoreArr[n].isChecked && !checkMoreArr[n].answer" class="err" :key="n">
                            <span :class="'option_span option_span_'+n"></span>
                        </li>
                        <li v-else-if="submitStatu && checkMoreArr.length>0 && !checkMoreArr[n].isChecked && checkMoreArr[n].answer" class="no" :key="n">
                            <span :class="'option_span option_span_'+n"></span>
                        </li>
                        <li v-else :key="n" @click="optClick(n)">
                            <span :class="'option_span option_span_'+n"></span>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <div class="error-txt" v-if="topicIsTrue===false">
            请仔细对照分步解析
        </div>
        <div class="succ-txt" v-if="topicIsTrue===true">
            <p class="p1">厉害了！耗时{{time}}</p>
            <p class="p2 on" v-if="star_this==5">您已掌握本专题，马不停蹄，下一专题</p>
            <p class="p2" v-else-if="endTime-startTime>120000">继续加油，试着在<span class="err-on">2分钟内</span>做对</p>
            <p class="p2" v-else>继续加油，很快就要完成了</p>
        </div>
        <template v-if="$route.name=='shuati'">
            <template v-if="submitStatu">
                <van-button v-if="star_this==5 && topicIsTrue" @click="nextzhuanti" type="primary" block>下一专题</van-button>
                <van-button v-else type="primary" @click="next" block>下一题</van-button>
            </template>
            <template v-else>
                <van-button type="primary" @click="submit" block>提  交</van-button>
            </template>
        </template>
        <template v-else-if="$route.name=='zhenti'">
        </template>
        <template v-else>
            <div v-if="topicIsTrue" class="changeNext">
                <van-button type="primary" url="#/shuati">开始刷题</van-button>
                <van-button type="primary" @click="changeNext">换一专题</van-button>
            </div>
            
            <van-button v-else-if="!submitStatu" type="info" @click="submit" block>提  交</van-button>
        </template>
    </div>
</template>

<script>
    var $ = require( "jquery" );
    export default {
        data() {
            return {
                checkNum:'', // 单选选项
                checkMoreArr: [], // 多选选中数组
                submitStatu: false, // 填空只允许提交一次
                active:'', // 选项颜色 err 选错了, succ 选对了, no 该选，但是未选 , 选中 on

                startTime:0,//倒计时开始时间
                endTime:0,//倒计时结束时间
                time: '',// 耗时
                star_this:0,
            }
        },
        props: {
            topicData: {
                type: Object,
                default: {}
            }
        },
        mounted () {
            this.showTopic();
        },
        computed: {
            topicIsTrue() {
                return this.$store.state.topicIsTrue;
            },
            getNavInfo() {
                return this.$store.state.getNavInfo;
            },
            stepShow() {
                return this.$store.state.stepShow;
            }
        },
        watch: {
            stepShow(newValue, oldValue) {
                console.log('newValue',newValue)
                this.submitStatu = newValue;
            }
        },
        methods: {
            changeNext(){
                this.$parent.barsClick();
            },
            // 题 展示
            showTopic() {
                this.startTime = +new Date();
                let topicData = this.topicData;
                console.log("topicData['例题']", topicData);
                if(Object.keys(topicData).length>0){
                    console.log("topicData['例题']", topicData['例题']);
                    topicData['例题'].map((e,n)=>{
                        if(e[0]=='tex'){
                            let dom = document.getElementById('tex_item_'+n);
                            dom && this.$kx.render(e[1], dom, {
                                displayMode: true,
                            });
                        }
                    })
                    

                    this.$store.commit('getTopicIsTrue',''); 

                    this.submitStatu = false;

                    this.checkNum = '';
                    this.active = '';

                    this.checkMoreArr = [];

                    let type = topicData['答题方式'];
                    switch (type) {
                        case '填空':
                            //填空题——拿问号变输入框
                            this.$nextTick(()=>{
                                let mcloseArr = $('#topicMain').find('.cjk_fallback');
                                $.each(mcloseArr, function (index) {
                                    let item = mcloseArr[index];
                                    if ($(item).html() == "冇") {
                                        $(item).html("<input type='text' class='inputClass'>")
                                    }
                                });
                            })

                            break;
                        case '单选':
                            this.$nextTick(()=>{
                                if(topicData['选项']){
                                    topicData['选项'].map((e,n)=>{
                                        let domJie = document.getElementsByClassName('option_span_'+n)[0];
                                        this.$kx.render(e, domJie, {
                                            displayMode: true,
                                        });
                                    })
                                }else{
                                    topicData['选项'].map((e,n)=>{
                                        let domJie = document.getElementsByClassName('option_span_'+n)[0];
                                        this.$kx.render(e, domJie, {
                                            displayMode: true,
                                        });
                                    })
                                }
                                    
                            });
                            break;
                        case '多选':

                            topicData['解'].map((e,n)=>{
                                this.$set(this.checkMoreArr, n, {
                                    isChecked: false,
                                    result: '', // 选中结果
                                    answer: e,
                                })
                            });
                            this.$nextTick(()=>{
                                setTimeout(() => {
                                    topicData['选项'].map((e,n)=>{
                                        let domJie = document.getElementsByClassName('option_span_'+n)[0];
                                        this.$kx.render(e, domJie, {
                                            displayMode: true,
                                        });
                                    });

                                }, 1000);
                                    
                            });
                            break;
                        default:
                            break;
                    }

                }
            },
            //  提交
            async submit(){
                let leftInfo;
                try {
                    leftInfo = this.getNavInfo;
                    console.log('leftInfo',leftInfo);

                } catch (error) {

                }
                let topicResult;
                let topicData = this.topicData;
                let jie = topicData['解'];
                let type = topicData['答题方式'];
                if(type === '填空'){
                    if(this.submitStatu){
                        return false; 
                    }
                    let dataArr= [],
                    inputClass= $('#topicMain .inputClass');
                    inputClass.map((n,e)=>{
                        dataArr.push(e.value.toString());
                    });
                    this.topicData["解"].map((e,i)=>{
                        inputClass.map((n,el)=>{
                            if(el.value.toString() !== e.toString() && i == n){
                                if(el.value===''){
                                    el.value="?"
                                }
                                topicResult = false;
                                $(el).before('<span class="jie-err">'+el.value+'</span><span class="jie-succ">'+e+'</span>')
                            }
                            else if(el.value !=='' && el.value == e && i == n){
                                topicResult = true;
                                $(el).before('<span class="jie-succ">'+e+'</span>');
                            }
                        });
                        inputClass.hide();
                        return e.toString()
                    })
                }
                else if(type === '单选') {
                    let jie = topicData["解"];
                    if(jie[this.checkNum]){
                        this.active = 'succ';
                        topicResult = true;
                    }else{
                        this.active = 'err';
                        topicResult = false;
                    }
                }else if(type === '多选'){
                    topicResult = true; 
                    this.checkMoreArr.map(e=>{
                        if(e.isChecked !== e.answer){
                            topicResult = false;
                        }
                    })
                }

                this.submitStatu = true;
                this.timeEnd();
                if(topicResult){
                    localStorage.errNum = 0;
                }else{
                    this.$nextTick(()=>{
                        $('.topic-box').addClass('light');
                        setTimeout(() => {
                            $('.topic-box').removeClass('light');
                        }, 5000);
                    })
                    if(localStorage.errNum===undefined || localStorage.errNum==0){
                        localStorage.errNum=1
                    }else{
                        localStorage.errNum++;
                    }
                    if(localStorage.errNum>1 && this.$route.name=='shuati'){
                        $('#step-van-collapse').addClass('light');
                        setTimeout(() => {
                            $('#step-van-collapse').removeClass('light');
                        }, 2000);
                    }
                }
                this.$store.commit('getTopicIsTrue',topicResult); 
                // this.$store.commit('setNavInfo',leftInfo);
                this.startTime = +new Date();

                let name=this.$route.name
                if(name==='shuati'){//刷题才提交星星
                    let leftInfoData = leftInfo.data;
                    let zhuanti = leftInfoData.childs[leftInfoData.current_level];
                    let param = {
                        zhuanti: zhuanti.nav_id,
                        obj: topicResult?1:-1
                    }
                    await this.$store.commit('jindu_write',param);
                    
                    setTimeout(() => {
                        this.getStar(zhuanti.nav_id);
                    }, 500);
                }
                
            },
            // 选择题选项
            optClick(e){
                let topicData = this.topicData;
                if(this.submitStatu){
                    return false;
                }
                if(topicData['答题方式']=='单选'){
                    this.checkNum = e;
                    this.active = 'on';
                } else if(topicData['答题方式']=='多选') {
                    this.checkMoreArr[e].isChecked = !this.checkMoreArr[e].isChecked;
                    console.log('this.checkMoreArr', this.checkMoreArr);
                }

            },
            // 倒计时结束 清算
            timeEnd(){
                let endTime = +new Date();
                let startTime = this.startTime;
                let allTime = endTime - startTime;
                let s = (allTime/1000)/60;// 耗时几分钟
                let m = (allTime/1000)%60;
                let time = '';//s+'分'+m+'秒'
                if(s>=1){
                    time = parseInt(s) + '分';
                }
                if(m>=1){
                    time += parseInt(m) + '秒';
                }
                this.time = time;
            },
            // 下一题
            next(){
                this.$parent.next();
            },
            getStar(id){
                let param={
                    zhuanti:id
                }
                this.$api.jindu_query({
                    param,method:'post'
                },res=>{
                    this.$parent.star_this = res.stars;
                    this.star_this = res.stars;
                    console.log(res, this.star_this);

                    let outlines = res.outlines;
                    let zhenti_name = res.zhenti_name;
                    
                    if(zhenti_name){
                        this.$dialog.alert({
                            title: '考纲',
                            message: outlines.join(','),
                        }).then(() => {
                            // on close
                            this.$router.push({path:'zhenti'})
                        });
                    }

                });
            },
            // 下一专题
            async nextzhuanti(){
                let getNavInfo = this.$store.state.getNavInfo;
                let navList = this.$store.state.navList;
                if(navList.length==getNavInfo.showNum+1){
                    return
                }
                navList.map((e,n)=>{
                    if(e.childs.length==getNavInfo.checkNum+1 && getNavInfo.showNum==n){
                        // getNavInfo.showNum++;
                        getNavInfo.data = navList[getNavInfo.showNum+1].childs[0];
                    }else{
                        e.childs.map((el,i)=>{
                            if(getNavInfo.showNum==n && (getNavInfo.checkNum+1) ==i){
                                getNavInfo.data = el;
                            }
                        })
                    }
                });
                console.log('getNavInfo',getNavInfo);
                
                let param = {
                    dangqianzhuanti: getNavInfo.data.name,
                    dangqianwancheng: ''
                }
                this.$api.user_info_write({param,method:'post'},res=>{
                    this.$store.commit('getNavList');
                });
            }
        },
    }
</script>

<style lang="less" scoped>
    .topic{
        &-box{
            margin: 12px 0;
            background: #F4F6FD;
            border-radius: 2px;
            padding: 32px 0;
            &.succ{
                background: #EAFAF5;
            }
            &.err{
                background: #FFECEA;
                &.light{

                    animation:mymove 1s infinite;
                    @keyframes mymove
                    {
                        from {background: #F4F6FD;}
                        to {background: #FFECEA;}
                    }
                }
            }

            .option{
                padding-top: 12px;
                ul{
                    // display: flex;
                    // flex-wrap:wrap;
                    li{
                        background: #FFFFFF;
                        overflow: auto;
                        border-radius: 2px;
                        border: 1px solid #E6E8EF;
                        text-align: left;
                        padding: 8px 12px;
                        margin: 6px 16px;
                        box-sizing: border-box;
                        cursor: pointer;
                        
                        .option_span{
                            display: block;
                            /deep/.katex-display{
                                margin: 0;
                            }
                        }
                        .el-icon-success{
                            display: none;
                        }
                        &.on{
                            color: #fff;
                            background: #415FD5;
                            border-radius: 2px;
                        }
                        &.no{
                            color: #15C392;
                            border-radius: 2px;
                        }
                        &.err{
                            color: #fff;
                            background: #FF4236;
                            border: 1px solid #FF4236;
                        }
                        &.succ{
                            color: #fff;
                            background: #15C392;
                            border: 1px solid #15C392;
                        }
                        :hover{

                        }
                    }
                    &.duoxuan{
                        li{
                            .el-icon-success{
                                display: none;
                            }
                            &.on{
                                color: #fff;
                                background: #6F9AE1;
                                border: 1px solid #6F9AE1;
                            }
                            &.active{
                                color: #fff;
                                background: #4BD794;
                                border: 1px solid #4BD794;
                            }
                            &.err{
                                color: #fff;
                                background: #F35656;
                                border: 1px solid #F35656;
                            }
                            &.succ{
                                .el-icon-success{
                                    display: inline-block;
                                    float: right;
                                    margin-top: 12px;
                                    margin-right: 12px;
                                    color: #4BD794;
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-con{
            overflow: auto;
        }
        #topicMain{
            text-align: center;
        }
        .changeNext{
            display: flex;
            justify-content: space-between;
            .van-button{
                width: 45%;
            }
        }
        .error-txt{
            width: 182px;
            margin: 0 auto;
            text-align: center;
            height: 38px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #606580;
            line-height: 19px;
            padding-top: 4px;
            padding-bottom: 16px;
        }
        .succ-txt{
            padding-top: 4px;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            line-height: 19px;
            text-align: center;
            padding-bottom: 16px;
            .p1{
                color: #2A3155;
                padding-bottom: 4px;
            }
            .p2{
                color: #9498AA;
                &.on{
                    color: #15C392;
                }
                &.err-on{
                    color: #FF4236;
                }
            }
        }
    }
</style>