<template>
    <div class="special">
        <div class="special-title">
            <span class="barsLightSp">
                <span class="sp">
                    <van-icon name="bars" class="barsLight" @click="barsClick" />
                </span>
            </span>
            <span class="txt-con">
                <span class="txt">刷题：{{specialName}}</span>
                <span class="huan" @click="next" v-if="isNext"><van-icon name="replay" />换一题</span>
            </span>
        </div>

        <div class="shuati">
            <!-- 专题解析 -->
            <van-collapse v-model="activeNames">
                <van-collapse-item :title="(leftInfo.checkNum+1)+'、'+titleName+'：专题解法'" name="1">
                    <div class="analysis" id="analysis"></div>
                </van-collapse-item>
            </van-collapse>

            <div>
                <!-- 做题 -->
                <div class="special-panel mb_16">
                    <!-- <div class="star-con"></div> -->
                    <!-- <p class="pl_5 pt_10 pb_30">{{topicData['答题方式']}}</p> -->
                    <div class="difficulty">
                        <div>
                            <span class="dtfs"> {{topicData['答题方式']}}</span> 
                            难度：
                            <span :class="leftInfo.level == item.level?'levelOn':leftInfo.level>n?'levelSucc':''" v-for="(item,n) in leftInfo.data.childs" :key="n">
                                <span class="radio-sp"></span>{{item.level}}级
                            </span>
                        </div>
                        <van-rate readonly v-model="star_this" :size="15" color="#F9C200" />
                    </div>
                    <div class="topicRefCon">
                        <topic ref="topicRef" :topicData="topicData" />
                    </div>
                </div>

                <Step ref="stepRef" :topicData="topicData" />

                <!-- <div class="special_foot" v-if="topicIsTrue===true || topicIsTrue === false">
                    <van-button class="next" type="primary" @click="next" block>下一题</van-button>
                </div> -->
            </div>
        </div>
        
        <navLeft ref="nav" @getActive="getActive" :navList="navList" active="active" />
        
        <van-icon @click="backTop" class="backTop" name="back-top" />

    </div>
</template>

<script>
    import navLeft from './component/leftNav';
    import topic from './component/topic';
    import Step from './component/Step';
    import utils from '@/assets/js/utils';
    var $ = require( "jquery" );
    export default {
        name:'special',
        data() {
            return {
                star_this:0,//当前星星
                activeNames:[],
                showNum: 0,//左侧菜单展开那个
                leftInfo:{
                    showNum:0, //当前展开 
                    checkedNum:0, // 当前选中
                    data:'',
                    level:1, //难度
                },
                currentInfo:{}, // 当前页面的左侧菜单展示信息

                topicData:{}, // 获取的题的内容
                specialName:'',//专题名称
                titleName: '', //标题名称

                zhentiNum: 0,
                isNext:true, //下一题是否展示
            }
        },
        components: {
            navLeft,
            topic,
            Step
        },
        created () {
            this.$store.commit('getNavList');
        },
        mounted () {
            this.init();
        },
        watch: {
            activeNames(newValue, oldValue) {
                this.getAnalysis();
            },
            navList(val){
                if(val && val.length>0){
                    this.$refs.nav.init(val);
                }
            },
        },
        computed: {
            navList() {
                return this.$store.state.navList;
            },
            topicIsTrue() {
                return this.$store.state.topicIsTrue;
            },
            getNavInfo(){
                return this.$store.state.getNavInfo;
            }
        },
        methods: {
            backTop(){
                document.documentElement.scrollTop=0;
                document.body.scrollTop=0;
                $('.barsLight').addClass('on')
                // setTimeout(() => {
                //     $('.barsLight').removeClass('on')
                // }, 3000);
            },
            init() {
                // 获取菜单
                let query = this.$route.query;
                this.active = +query.active || 0;
                if(this.$store.state.navList.length>0){
                    this.$refs.nav.init(this.$store.state.navList);
                }
            },
            // 左侧菜单展现
            barsClick(){
                this.$refs.nav.showNav = true;
                this.$refs.nav.getStar();
            },
            // 获取菜单选中信息
            getActive(leftInfo){
                this.leftInfo = leftInfo;
                let currentInfo
                let query = this.$route.query;
                if(query && query.name=="zhenti"){
                    currentInfo = leftInfo.data;
                    console.log('currentInfo',currentInfo);
                }else{
                    currentInfo = leftInfo.data.childs[leftInfo.data.current_level];
                    this.specialName = this.navList[leftInfo.showNum].name;
                    this.titleName = leftInfo.data.name;
                    this.titleName = leftInfo.data.name;
                }
                
                this.currentInfo = currentInfo;
                this.showTopic();
                this.getAnalysis();
            },
            // 加载解析
            getAnalysis(){
                let {solution_dir}=this.currentInfo;
                console.log('solution_dir', solution_dir);
                let url=solution_dir;
                this.$ajax({url,
                    success:(res=>{
                        let dom = document.getElementById('analysis');
                        dom && this.$kx.render(res['方法'], dom, {
                            displayMode: true,
                        });
                    })
                })
            },
            // 展示题
            showTopic(){
                let {dir,nav_id}=this.currentInfo;
                console.log('dir', dir);
                this.getStar(nav_id);
                let url=dir;
                this.$ajax({url,
                    success:(res=>{
                        let query = this.$route.query;
                        if(query && query.name=="zhenti"){
                            this.topicData = res[this.zhentiNum];
                        }else{
                            this.topicData = res;
                            this.$nextTick(()=>{
                                this.$refs.topicRef.showTopic();
                                this.$refs.stepRef.init();
                            });
                        }
                    })
                })
            },
            getStar(nav_id){
                
                let param={
                    zhuanti:nav_id
                }
                this.$api.jindu_query({
                    param,method:'post'
                },res=>{
                    this.star_this = +res.stars;
                })
            },

            next(){
                // this.$refs.nav.init(this.navList);
                this.$store.commit('getNavList');
                this.$store.commit('getTopicIsTrue',''); 
                this.activeNames = [];
                let that = this;
                this.isNext = false;
                setTimeout(() => {
                    that.isNext = true;
                }, 2000);
            },

            ltlx(){
                this.activeNames = [];
            }
        },
    }
</script>

<style lang="less" scoped>
    .special{
        padding: 12px;
        background: #F7F8FB;
        text-align: left;
        height: 100vh;
        &-title{
            font-size: 22px;
            text-align: left;
            padding-top: 12px;
            display: flex;
            .barsLightSp{
                position: fixed;
                left: 0px;
                top: 250px;
                z-index: 9;
                // background: #fff;
                line-height: 60px;
                display: inline-block;
                overflow: hidden;
                width: 30px;
                height: 60px;
                .sp{
                    width: 60px;
                    height: 60px;
                    border-radius: 100%;
                    background: #fff;
                    display: inline-block;
                    position: absolute;
                    left: -100%;
                }
            }
            .van-icon-bars{
                position: absolute;
                left: 32px;
                color: #415FD5;
                position: relative;
                top: 4px;
                &.on{
                    animation:mymove 1s infinite;
                    @keyframes mymove
                    {
                        from {color: #15C392;}
                        to {color: #415FD5;}
                    }
                }
            }
            .txt-con{
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
            .huan{
                font-size: 14px;
                font-family: MicrosoftYaHei;
                color: #606580;
                line-height: 30px;
                .van-icon-replay{
                    position: relative;
                    top: 2px;
                    margin-right: 5px;
                }
            }
            .txt{
                margin-bottom: 16px;
                font-size: 22px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #2A3155;
                line-height: 29px;
            }
        }
        &-panel{
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(42, 49, 85, 0.1);
            border-radius: 2px;
            margin-top: 16px;
            box-sizing: border-box;
            padding: 16px 12px;
            .star-con{
                padding-bottom: 16px;
                text-align: center;
            }
            .difficulty{
                min-height: 48px;
                background: #F4F6FD;
                border-radius: 2px;
                // line-height: 32px;
                padding: 10px 0;
                text-align: center;
                color: #9498AA;
                position: relative;
                .dtfs{
                    position: absolute;
                    left: 10px;
                    color: #333;
                }
                .radio-sp{
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background: #FFFFFF;
                    border: 2px solid #9498AA;
                    border-radius: 6px;
                    margin-right: 5px;
                    margin-left: 10px;
                }
                .levelOn{
                    color: #415FD5;
                    .radio-sp{
                        border: 2px solid #415FD5
                    }
                }
                .levelSucc{
                    color: #15C392;
                    .radio-sp{
                        border: 2px solid #15C392
                    }
                }
            }
        }
        .topicRefCon{
            
        }
        .analysis{
            overflow: auto;
        }
        &_foot{
            height: 72px;
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(42, 49, 85, 0.1);
            border-radius: 2px;
            box-sizing: border-box;
            padding: 12px;
            margin-bottom: 50px;
            // .next{
            //     position: relative;
            //     z-index: 9;
            // }
            
        }
        .backTop{
            position: fixed;
            bottom: 20px;
            right: 10px;
            font-size: 24px;
            z-index: 9;
        }
    }
</style>